//$('ul').hover(function(){
//    $('#darkness').fadeTo(200, 1);
//}, function(){
//    $('#darkness').fadeTo(200, 0, function(){
//        $(this).hide();
//    });
//});

//$('#message').hover(function(){
//    $('#darkness').fadeTo(200, 1);
//}, function(){
//    $('#darkness').fadeTo(200, 0, function(){
//        $(this).hide();
//    });
//});

// TODO fix code to darken screen on focus
